import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import SelectedProcedure from "./SelectedProcedure";
import SelectedHospital from "./SelectedHospital";
import BackIcon from "../Assets/icon-back.svg";

let dotsColor = "";

const SelectedWrapper = styled.div`
  width: 348px;
  position: fixed;
  text-align: left;

  @media (max-width: 879px) {
    position: static;
    width: 100%;
    padding: 0 16px;
  }
`;

const TopDots = styled.div`
  display: flex;
  margin-bottom: 6px;
  & > div {
    height: 4px;
    width: 4px;
    border-radius: 50%;
    margin-right: 6px;
    background-color: #8585e0;
    background-color: ${props => props.dotsColor};
  }
`;

const BottomDots = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;
  & > div {
    height: 4px;
    width: 4px;
    border-radius: 50%;
    margin-left: 6px;
    background-color: #8585e0;
    background-color: ${props => props.dotsColor};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
`;

const ColumnDots1 = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4px;
  & > div {
    height: 4px;
    width: 4px;
    border-radius: 50%;
    margin-bottom: 6px;
    background-color: #8585e0;
    background-color: ${props => props.dotsColor};
  }
`;

const ColumnDots2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 4px;
  & > div {
    height: 4px;
    width: 4px;
    border-radius: 50%;
    margin-top: 6px;
    background-color: #8585e0;
    background-color: ${props => props.dotsColor};
  }
`;

const Content = styled.div`
  flex-grow: 1;
  padding: 32px 24px;
  background-color: white;
  border-radius: 4px;

  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
`;

const ShowingResults = styled.div`
  font-size: 14px;
  color: #84869a;
  margin-bottom: 16px;
`;

const ButtonLink = styled.button`
  display: flex;
  align-items: center;

  padding: 12px 16px;
  margin-top: 0;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  color: #84869a;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  :hover {
    background-color: #f8f8fc;
  }
  :hover > img {
    transform: translateX(-4px);
  }

  @media (max-width: 879px) {
    padding: 0;
    background: none;
    margin-left: 8px;
  }
`;

const IconWrapper = styled.img`
  opacity: 0.5;
  margin-right: 4px;
  padding-top: 2px;
  transition: transform 0.2s ease-out;
`;

class SelectedParent extends React.Component {
  constructor() {
    super();
  }

  componentWillMount() {
    if (this.props.searchType === "procedures") {
      dotsColor = "#8585e0";
    } else {
      dotsColor = "#ED9DD9";
    }
  }

  componentDidMount() {
    // console.log(this.props.searchResults);
    // console.log(this.props.selectedThing);
  }

  render() {
    return (
      <SelectedWrapper>
        <TopDots dotsColor={dotsColor}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </TopDots>
        <TopDots dotsColor={dotsColor}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </TopDots>
        <ContentWrapper>
          <ColumnDots1 dotsColor={dotsColor}>
            <div />
            <div />
            <div />
            <div />
          </ColumnDots1>
          <Content>
            <ShowingResults>Showing results for:</ShowingResults>
            {this.props.searchType === "procedures" ? (
              <SelectedProcedure
                selectedProcedure={this.props.selectedThing}
                searchResults={this.props.searchResults}
              />
            ) : (
              <SelectedHospital
                selectedHospital={this.props.selectedThing}
                searchResults={this.props.searchResults}
              />
            )}
          </Content>
          <ColumnDots2 dotsColor={dotsColor}>
            <div />
            <div />
            <div />
            <div />
          </ColumnDots2>
        </ContentWrapper>
        <BottomDots dotsColor={dotsColor}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </BottomDots>
        <BottomDots dotsColor={dotsColor}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </BottomDots>
        <ButtonLink onClick={this.props.handleBack}>
          <IconWrapper src={BackIcon} />
          <span>Back to search</span>
        </ButtonLink>
      </SelectedWrapper>
    );
  }
}

SelectedParent.propTypes = {
  selectedThing: PropTypes.object.isRequired,
  searchResults: PropTypes.array.isRequired,
  handleBack: PropTypes.func.isRequired,
  searchType: PropTypes.string.isRequired
};

export default SelectedParent;
