import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { motion } from "framer-motion";

import Modal from "react-modal";
import ModalContent from "./ModalContent";
import Search from "./Search";

import Wordmark from "../Assets/lora-wordmark-white.svg";
import Headshot from "../Assets/headshot.png";

const SearchPageStyle = styled.div`
  // height: 100vh;
  width: 100%;
  // padding: 0 20px;
  background: #f7f8fd;

  @media (max-width: 879px) {
    // padding: 0 20px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 879px) {
    display: block;
  }
`;

const LeftPane = styled.div`
  width: 38vw;
  height: 100vh;
  background-color: #23295c;
  padding: calc(32px + 2vw);
  padding-top: 40px;
  padding-bottom: 40px;
  color: white;
  overflow-y: scroll;

  @media (max-width: 879px) {
    width: 100vw;
    padding: 20px;
    // padding-bottom: 0px;
    height: auto;
    max-height: ${props => (props.mobileIsVisible ? "1600px" : "40vh")};
    transition: max-height 1s;
    overflow: hidden;
  }
`;

const AboutContentWrapper = styled.div`
  margin-top: 128px;

  @media (max-width: 879px) {
    margin-top: 48px;
    visibility: ${props => (props.mobileIsVisible ? "visible" : "hidden")};
    padding-bottom: 100px;
  }
`;

const SiteDescription = styled.div`
  // margin-top: 128px;
  line-height: 1.6;

  @media (max-width: 879px) {
    // margin-top: 48px;
  }
`;

const H5 = styled.h5`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 16px;
`;

const DescParagraph = styled.p`
  margin-top: 16px;
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
`;

const AboutWrapper = styled.a`
  margin-top: 40px;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 24px;
  border-radius: 10px;
  display: flex;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  :hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 879px) {
    // display: none;
    padding: 20px 16px;
  }
`;

const StyledHeadshot = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin-right: 16px;
  flex-shrink: 0;
`;

const AboutMe = styled.div`
  flex-grow: 1;
`;

const H4 = styled.h4`
  font-weight: 600;
  margin-bottom: 8px;
  font-family: "Lora", serif;
`;

const AboutDesc = styled.p`
  font-size: 14px;
  line-height: 1.6;
  font-weight: 400;
`;

const ShowButton = styled.button`
  display: none;

  @media (max-width: 879px) {
    opacity: 0.7;
    display: block;
    padding: 8px 0;
    background-color: transparent;
    color: white;
    font-size: 14px;
    font-weight: 500;
    // border-bottom: solid 1px green;
    visibility: ${props => (props.mobileIsVisible ? "hidden" : "visible")};
  }
`;

const HideButton = styled.button`
  display: none;

  @media (max-width: 879px) {
    display: block;
    margin-top: 48px;
    width: calc(100vw - 40px);
    padding: 20px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    position: fixed;
    bottom: 32px;
    background-color: white;
    color: #4a58db;
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.3);
  }
`;

const RightPane = styled.div`
  width: 62vw;
  height: 100vh;
  background-color: white;
  background-color: #fbfbfe;
  padding: 64px;
  padding-top: 160px;

  @media (max-width: 879px) {
    width: 100vw;
    height: auto;
    min-height: 60vh;
    padding: 20px;
    padding-top: 48px;

    display: ${props => (props.mobileIsVisible ? "none" : "block")};
  }
`;

const Logo = styled.img`
  width: 204px;

  @media (max-width: 879px) {
    width: 172px;
  }
`;

const H1 = styled(motion.h1)`
  font-size: 44px;
  font-weight: 700px;
  line-height: 1.4;
  margin-top: 88px;
  margin-bottom: 72px;
  color: white;
  font-family: "Lora", serif;

  @media (max-width: 879px) {
    font-size: 32px;
    margin-top: 8vh;
    margin-bottom: 3vh;
  }
`;

const StyledModal = styled(Modal)`
  width: 640px;
  height: auto;
  margin: 10vh auto;
  outline: none;

  @media (max-width: 879px) {
    width: calc(100vw - 32px);
  }
`;

class SearchPage extends React.Component {
  constructor() {
    super();
    this.state = {
      searchFocus: false,
      modalIsOpen: false,
      modalId: "",
      mobileAboutVisible: false
    };
  }

  getPlaceholderString = () => {
    let procedureSet = [
      "chest pain",
      "newborn",
      "kidney transplant",
      "cesarean section",
      "heart failure"
    ];
    let randomInt = Math.floor(Math.random() * Math.floor(procedureSet.length));

    // let hospitalSet = ['UCSF, ']
    if (this.props.searchType === "procedures") {
      return procedureSet[randomInt];
    } else {
      return "California hospitals";
    }
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  afterOpenModal = () => {
    // console.log("modal id is " + this.state.modalId);
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false, modalId: "" });
  };

  handleDropdownSelect = id => {
    this.setState({ modalIsOpen: true, modalId: id });
  };

  handleSearchFocus = event => {
    if (event === "focus") {
      this.setState({
        searchFocus: true
      });
    } else if (event === "blur") {
      this.setState({
        searchFocus: false
      });
    }
  };

  toggleAboutVisible = () => {
    console.log("heylll");
    this.setState({ mobileAboutVisible: !this.state.mobileAboutVisible });
  };

  render() {
    return (
      <SearchPageStyle>
        <StyledModal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
        >
          {this.state.modalId === "cali" && (
            <ModalContent
              title={"Only California data available right now"}
              content={[
                <span>
                  Apologies for this. In short, getting hospital cost data is
                  not the easiest thing in the world (hence why I made this site
                  😉). California is a populous state that has some solid data,
                  so made sense to start there. Maybe I'll work to get data for
                  other states some day...
                </span>
              ]}
              closeModal={this.closeModal}
            />
          )}
        </StyledModal>
        <ContentWrapper>
          <LeftPane mobileIsVisible={this.state.mobileAboutVisible}>
            <Logo src={Wordmark} />
            <H1>View and compare hospital costs</H1>
            <ShowButton
              mobileIsVisible={this.state.mobileAboutVisible}
              onClick={this.toggleAboutVisible}
            >
              Read more ->
            </ShowButton>

            <AboutContentWrapper
              mobileIsVisible={this.state.mobileAboutVisible}
            >
              <SiteDescription mobileIsVisible={this.state.mobileAboutVisible}>
                <H5>An attempt at price transparency in healthcare.</H5>
                <DescParagraph>
                  Prices in healthcare are life-changing (
                  <span className="link-wrapper">
                    <a
                      className="link search-link"
                      href="https://www.cnbc.com/2019/02/11/this-is-the-real-reason-most-americans-file-for-bankruptcy.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      the bad kind
                    </a>
                  </span>
                  ) yet notoriously opaque. In 2019, a bill was passed requiring
                  hospitals to publicly post their "chargemasters": essentially,
                  a list of services and associated prices for each. Problem
                  solved, right?
                </DescParagraph>

                <DescParagraph>
                  Mmm no. Chargemasters display services using esoteric industry
                  jargon, rendering them all but{" "}
                  <span className="link-wrapper">
                    <a
                      className="link search-link"
                      href="https://www.nbcnews.com/news/us-news/hospital-price-list-chargemaster-rules-trump-mandate-2019-n959006"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      useless
                    </a>
                  </span>{" "}
                  to anyone outside of healthcare. Worse, they aren't even
                  comparable (data folks: they don't join). That's right: each
                  hospital uses their own internal terminology and codes for
                  their prices, making price comparison impossible for
                  consumers. How convenient!
                </DescParagraph>

                <DescParagraph>
                  This price opacity is especially problematic by virtue of
                  being in healthcare: costs are exorbitant, unpredictable, and
                  importantly,{" "}
                  <span className="link-wrapper">
                    <a
                      className="link search-link"
                      href="https://www.hioscar.com/deepdive/state-of-us-healthcare"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      not correlated
                    </a>
                  </span>{" "}
                  with quality of care. I find all of this pretty darn
                  frustrating, so I made this tool as an experiment.
                </DescParagraph>

                <DescParagraph>
                  Of course, this problem is incredibly complex and this site
                  doesn't come close to being comprehensive (e.g. it doesn't
                  take into account insurance or Medicare. Which btw, finding
                  prices post-insurance negotiation is impossible woo). But
                  hopefully just exposing these debilitating hospital costs and
                  how they compare (with an eye for simplicity) is a start? Idk
                  you{" "}
                  <span className="link-wrapper">
                    <a
                      className="link search-link"
                      href="mailto:lawderpaul@gmail.com"
                      rel="noopener noreferrer"
                    >
                      tell me
                    </a>
                  </span>
                  .
                </DescParagraph>
              </SiteDescription>

              <AboutWrapper
                href="https://twitter.com/lawderpaul"
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledHeadshot src={Headshot} />
                <AboutMe>
                  <H4>Made by Lawder Paul</H4>
                  <AboutDesc>
                    I’m a designer and developer in San Francisco, passionate
                    about making the complex simple.
                  </AboutDesc>
                </AboutMe>
              </AboutWrapper>

              <HideButton onClick={this.toggleAboutVisible}>
                Back to search
              </HideButton>
            </AboutContentWrapper>
          </LeftPane>

          <RightPane mobileIsVisible={this.state.mobileAboutVisible}>
            <motion.div
              transition={
                this.props.firstSearch
                  ? {
                      duration: 0.4,
                      delay: 1
                    }
                  : { duration: 0.3, delay: 0.1 }
              }
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              // exit={{ scale: 5 }}
            >
              <form>
                <input
                  type="radio"
                  id="procedures"
                  name="searchType"
                  value="procedures"
                  onChange={e => this.props.handleRadioSelect(e)}
                  defaultChecked={this.props.searchType === "procedures"}
                />
                <label htmlFor="procedures" className="label-procedures">
                  Procedures
                </label>
                <input
                  type="radio"
                  id="hospitals"
                  name="searchType"
                  value="hospitals"
                  onChange={e => this.props.handleRadioSelect(e)}
                  defaultChecked={this.props.searchType === "hospitals"}
                />
                <label htmlFor="hospitals" className="label-hospitals">
                  Hospitals
                </label>
              </form>
              <Search
                // on isMobile and state.focus, animate upwards on mobile
                searchType={this.props.searchType}
                handleFocus={this.handleSearchFocus}
                handleLoading={this.props.handleLoading}
                handleResults={(selection, results) =>
                  this.props.handleResults(selection, results)
                }
                // placeholderString={
                //   this.props.searchType === "procedures"
                //     ? "Try 'chest pain'"
                //     : "Try 'Zuckerberg'"
                // }
                placeholderString={this.getPlaceholderString()}
                handleTriggerModal={this.handleDropdownSelect}
                viewportWidth={this.props.viewportWidth}
                // this.props.handleResults(selection, results);
              />
            </motion.div>
          </RightPane>
        </ContentWrapper>
      </SearchPageStyle>
    );
  }
}

SearchPage.propTypes = {
  searchType: PropTypes.string.isRequired,
  handleLoading: PropTypes.func.isRequired,
  handleResults: PropTypes.func.isRequired,
  handleRadioSelect: PropTypes.func.isRequired,
  firstSearch: PropTypes.bool.isRequired,
  viewportWidth: PropTypes.number.isRequired
};

export default SearchPage;
