import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ProcedureName = styled.div`
  margin-bottom: 32px;
  color: #131739;
`;

const DRGCode = styled.p`
  font-size: 13px;
  margin-top: 4px;
  color: #84869a;
  font-family: "Roboto Mono", monospace;
`;

const Code = styled.span`
  font-weight: 600;
  // color: black;
`;

const StatFlex = styled.div`
  display: block;
  & :nth-child(2) {
    margin-top: 24px;
  }

  @media (max-width: 879px) {
    display: flex;
    & :nth-child(2) {
      margin-top: 0;
    }
  }
`;

const StatWrapper = styled.div`
  @media (max-width: 879px) {
    width: 50%;
  }
`;

const StatLabel = styled.h5`
  font-size: 12px;
  letter-spacing: 0.6px;
  font-weight: 700;
  text-transform: uppercase;
  color: #84869a;

  @media (max-width: 879px) {
    font-size: 11px;
  }
`;

const Stat = styled.p`
  font-size: 24px;
  margin-top: 4px !important;
  color: #131739;

  @media (max-width: 879px) {
    font-size: 20px;
  }
`;

class SelectedProcedure extends React.Component {
  constructor() {
    super();
    this.state = {
      avgCost: "",
      avgLOS: 0
    };
  }

  componentWillMount() {
    this.calculateAvgCost(this.props.searchResults);
  }

  calculateAvgCost = results => {
    let totalCost = 0;
    let totalDischarges = 0;
    for (var i = 0; i < results.length; i++) {
      let charges = Number(results[i].AvgChgPerStay.replace(/[^0-9.-]+/g, ""));
      let discharges = Number(results[i].Discharges.replace(",", ""));
      let validCharges = charges * discharges;
      totalCost += validCharges;
      totalDischarges += discharges;
    }

    let averageCost = totalCost / totalDischarges;
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    });
    let avgCost = formatter.format(averageCost);
    // call avg stay
    this.calculateAvgStay(results, totalDischarges, avgCost);
  };

  calculateAvgStay = (results, totalDischarges, avgCost) => {
    let totalDays = 0;
    for (var i = 0; i < results.length; i++) {
      let discharges = Number(results[i].Discharges.replace(",", ""));
      let stay = results[i].ALOS * discharges;
      totalDays += stay;
    }
    let averageStay = totalDays / totalDischarges;
    let avgStay = (Math.round(averageStay * 10) / 10).toFixed(1);

    this.updateState(avgCost, avgStay);
  };

  updateState = (avgCost, avgStay) => {
    this.setState({
      avgCost: avgCost,
      avgLOS: avgStay
    });
    // we'll just update the state once with both values
  };

  render() {
    return (
      <div>
        <ProcedureName>
          <h4>{this.props.selectedProcedure.PrimaryInfo}</h4>
          <DRGCode>
            DRG Code: <Code>{this.props.selectedProcedure.DRGCode}</Code>
          </DRGCode>
        </ProcedureName>
        <StatFlex>
          <StatWrapper>
            <StatLabel>Avg cost</StatLabel>
            <Stat>{this.state.avgCost}</Stat>
          </StatWrapper>
          <StatWrapper>
            <StatLabel>Avg length of stay</StatLabel>
            <Stat>{this.state.avgLOS} days</Stat>
          </StatWrapper>
        </StatFlex>
      </div>
    );
  }
}

SelectedProcedure.propTypes = {
  selectedProcedure: PropTypes.object.isRequired,
  searchResults: PropTypes.array.isRequired
};

export default SelectedProcedure;
