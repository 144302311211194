import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import iconClose from "../Assets/icon-close.svg";

const StyledModal = styled.div`
  width: 100%;
  margin: 10vh auto;
  max-height: 80vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  outline: none;
  background-color: white;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);

  @media (max-width: 879px) {
    padding: 8px;
    max-height: 70vh;
  }
`;

const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  // width: 100%;

  height: 36px;
`;

const StyledIcon = styled.img`
  height: 20px;
  width: 20px;
`;

const CloseButton = styled.button`
  position: fixed;
  // right: 0px;
  // top: 0px;

  height: 36px;
  width: 36px;
  padding: 8px;
  outline: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.18s ease-out;

  :hover {
    background-color: #f8f8fc;
  }
`;

const ContentWrapper = styled.div`
  padding: 24px;
  padding-top: 8px;

  @media (max-width: 879px) {
    padding: 16px;
    padding-top: 8px;
    padding-bottom: 24px;
  }
`;

const H2 = styled.h2`
  font-size: 18px;
  font-weight: 500px;
  line-height: 1.2;
  margin-bottom: 24px;
  color: #131739;
`;

const P = styled.p`
  font-size: 15px;
  line-height: 1.7;
  margin-bottom: 10px;
  color: #131739;

  :last-child {
    margin-bottom: 0;
  }
`;

let paragraphItems = [];

const mapParagraphs = content => {
  // console.log(content);
  paragraphItems = content.map(paragraph => (
    <P key={content.indexOf(paragraph)}> {paragraph}</P>
  ));
  return paragraphItems;
};

const ModalContent = props => (
  <StyledModal>
    <CloseWrapper>
      <CloseButton onClick={props.closeModal}>
        <StyledIcon src={iconClose} />
      </CloseButton>
    </CloseWrapper>
    <ContentWrapper>
      <H2>{props.title}</H2>
      <div>{mapParagraphs(props.content)}</div>
    </ContentWrapper>
  </StyledModal>
);

ModalContent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default ModalContent;
