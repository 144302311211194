import React from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";
import styled from "styled-components";

import FullData from "../Data/FullData.json";
import HospitalData from "../Data/Hospitals.json";
import search from "../Assets/icon-search.svg";

const searchVariants = {
  focus: { y: -200 },
  notFocus: { y: 0 }
};

const InputParent = styled.div`
  width: 500px;
  margin-top: 24px;
  padding: 16px 4px 16px 20px;
  background: white;
  display: flex;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);

  @media (max-width: 879px) {
    width: calc(100vw - 40px);
    padding: 16px 4px 16px 16px;
  }
`;

const SearchIcon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  flex-shrink: 0;

  @media (max-width: 879px) {
    margin-right: 8px;
  }
`;

const StateWrapper = styled.div`
  // width: 56px;
  padding: 0 16px;
  border-left: solid #e8e9ed 1px;
  font-size: 11px;
  color: #84869a;
  cursor: pointer;

  :hover > p {
    color: #131739;
  }
`;

const Cali = styled.p`
  margin-top: 2px;
  font-size: 15px;
  font-weight: 600;
  transition: color 0.15s ease-out;
`;

//donwshift
const SearchInput = styled.input`
  font-weight: 500;
  font-size: 18px;
  color: #131739;
  outline: none;
  border: none;
  width: 100%;
  padding: 4px 0;
`;

const Lightbox = styled.ul`
  max-width: 500px;
  max-height: 300px;
  overflow: scroll;

  background-color: white;
  margin-top: 8px;
  border-radius: 6px;
  padding: 8px;
  list-style-type: none;
  text-align: left;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

// should take in props: if searchType procedure, lowercase
const LightboxItem = styled.li`
  padding: 16px;
  color: #131739;
  border-radius: 6px;

  @media (max-width: 879px) {
    padding: 12px 8px;
  }
`;

let lightboxItems = [];
let searchType = "";

class Search extends React.Component {
  constructor() {
    super();
    this.state = {
      minLength: false,
      isFocused: false
    };
  }

  componentWillMount() {
    // start with the default
    if (this.props.searchType === "procedures") {
      this.gatherProcedures();
    } else {
      this.gatherHospitals();
    }
  }

  componentDidUpdate() {
    // for when the searchType changes (via prop)
    if (this.props.searchType === "procedures") {
      this.gatherProcedures();
    } else {
      this.gatherHospitals();
    }
  }

  gatherProcedures = () => {
    lightboxItems = [];
    searchType = this.props.searchType;
    for (var i = 0; i < FullData.length; i++) {
      let tempObject = {
        DRGCode: FullData[i].DRGCode,
        PrimaryInfo: FullData[i].DRGDescription.toLowerCase()
      };
      // maintain unique list of procedures
      if (!lightboxItems.some(e => e.DRGCode === tempObject.DRGCode)) {
        lightboxItems.push(tempObject);
      }
    }
  };

  gatherHospitals = () => {
    searchType = this.props.searchType;
    lightboxItems = [];
    for (var i = 0; i < FullData.length; i++) {
      let tempObject = {
        HospitalID: FullData[i].FacilityNumber,
        PrimaryInfo: FullData[i].FacilityName.toLowerCase(),
        HospitalLocation: ""
      };
      // maintain unique list of hospitals that have procedures
      if (!lightboxItems.some(e => e.HospitalID === tempObject.HospitalID)) {
        lightboxItems.push(tempObject);
      }
    }
    // for hospitals, we have to join to get location
    for (var j = 0; j < lightboxItems.length; j++) {
      for (var h = 0; h < HospitalData.length; h++) {
        if (lightboxItems[j].HospitalID == HospitalData[h].OSHPD_ID) {
          lightboxItems[j].HospitalLocation =
            HospitalData[h].DBA_CITY.toLowerCase() + ", CA";
        }
      }
    }
  };

  makeSelection = selection => {
    let results = [];

    // this.props.handleLoading();

    if (this.props.searchType === "procedures") {
      // search through data to filter to those that have this code
      for (var i = 0; i < FullData.length; i++) {
        let tempObject = {
          DRGCode: FullData[i].DRGCode,
          FacilityNumber: FullData[i].FacilityNumber,
          FacilityName: FullData[i].FacilityName.toLowerCase(),
          Discharges: FullData[i].Discharges,
          AvgChgPerStay: FullData[i].AvgChgPerStay,
          ALOS: FullData[i].ALOS
        };
        if (FullData[i].DRGCode === selection.DRGCode) {
          results.push(tempObject);
        }
      }
    } else {
      // define object, based on results
      for (var i = 0; i < FullData.length; i++) {
        let tempObject = {
          FacilityNumber: FullData[i].FacilityNumber,
          DRGCode: FullData[i].DRGCode,
          DRGDescription: FullData[i].DRGDescription.toLowerCase(),
          Discharges: FullData[i].Discharges,
          AvgChgPerStay: FullData[i].AvgChgPerStay,
          ALOS: FullData[i].ALOS
        };
        if (FullData[i].FacilityNumber === selection.HospitalID) {
          results.push(tempObject);
        }
      }
    }

    this.props.handleResults(selection, results);
  };

  checkLength = inputValue => {
    // this is used as a faux debounce; not sure it really helps performance
    let inputLength = inputValue.length;
    if (inputLength > 1) {
      this.setState({
        minLength: true
      });
    } else {
      this.setState({
        minLength: false
      });
    }
  };

  handleFocus = event => {
    if (event === "focus") {
      this.props.handleFocus("focus");
      this.setState({
        isFocused: true
      });
    } else if (event === "blur") {
      this.props.handleFocus("blur");
      this.setState({
        isFocused: false
      });
    }
  };

  selectCA = () => {
    // cant do generic cause click target isn't always parent div
    this.props.handleTriggerModal("cali");
  };

  render() {
    return (
      <div>
        <Downshift
          onChange={selection => this.makeSelection(selection)}
          itemToString={item => (item ? item.PrimaryInfo : "")}
          onInputValueChange={inputValue => this.checkLength(inputValue)}
        >
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            isOpen,
            inputValue,
            highlightedIndex,
            selectedItem
          }) => (
            <div>
              <InputParent>
                <SearchIcon src={search} />
                <SearchInput
                  {...getInputProps({
                    placeholder: `${this.props.placeholderString}`

                    // onFocus: () => this.handleFocus("focus"),
                    // onBlur: () => this.handleFocus("blur")
                  })}
                  autoFocus
                />
                <StateWrapper id={"cali"} onClick={e => this.selectCA(e)}>
                  State
                  <Cali>CA</Cali>
                </StateWrapper>
              </InputParent>
              {this.state.minLength && isOpen ? (
                <Lightbox {...getMenuProps()}>
                  {lightboxItems // should be agnostic
                    .filter(
                      item =>
                        !inputValue ||
                        item.PrimaryInfo.includes(inputValue.toLowerCase())
                    )
                    .map((item, index) => (
                      <LightboxItem
                        {...getItemProps({
                          key:
                            searchType === "procedures"
                              ? item.DRGCode
                              : item.HospitalID,
                          className:
                            searchType === "hospitals" ? "capitalize" : null,
                          index,
                          item,
                          style: {
                            backgroundColor:
                              highlightedIndex === index ? "#FCEEF9" : "white",
                            fontWeight:
                              selectedItem === item ? "bold" : "normal",
                            transition: "background-color 0.1s ease-out"
                          }
                        })}
                      >
                        {item.PrimaryInfo}
                      </LightboxItem>
                    ))}
                </Lightbox>
              ) : null}
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

Search.propTypes = {
  searchType: PropTypes.string.isRequired,
  handleFocus: PropTypes.func.isRequired,
  handleLoading: PropTypes.func.isRequired,
  handleResults: PropTypes.func.isRequired,
  placeholderString: PropTypes.string.isRequired,
  handleTriggerModal: PropTypes.func.isRequired,
  viewportWidth: PropTypes.number.isRequired
};

export default Search;
