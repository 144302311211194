import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { motion } from "framer-motion";

import Modal from "react-modal";

import ModalContent from "./ModalContent";

import hospitals from "../Data/Hospitals.json";
import averages from "../Data/Average.json";

const StyledModal = styled(Modal)`
  width: 600px;
  width: 800px;
  max-width: calc(70vw);
  height: auto;
  margin: 10vh auto;
  outline: none;

  @media (max-width: 879px) {
    width: calc(100vw - 32px);
    max-width: none;
    // margin: 15vh auto;
  }
`;

const ResultsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  padding-top: 0;
  color: #131739;

  @media (max-width: 879px) {
    padding: 16px;
  }
`;

const WTFButton = styled.button`
  padding: 8px 16px;

  border: solid 1px #e8e9ed;
  font-size: 14px;
  font-weight: 600;
  background-color: white;
  color: #212973;
  letter-spacing: 0.5px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: border-color 0.2s ease-out;

  :hover {
    border-color: #ed9dd9;
  }

  @media (max-width: 879px) {
    // padding: 16px;
  }
`;

const desktopList = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.05
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren"
    }
  }
};

const mobileList = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren"
    }
  }
};

const items = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: -50 }
};

const ResultsWrapper = styled(motion.ul)`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled(motion.li)`
  list-style: none;
  padding: 24px 16px;
  text-align: left;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #131739;

  :nth-child(odd) {
    background-color: ${props =>
      props.searchType === "procedures" ? "#f6f7fd" : "#fdf7fb"}
    border-radius: 2px;
  }

  @media (max-width: 879px) {
    width: 100%;
    font-size: 14px;
    padding: 24px 16px 24px 12px;
  }
`;

const DataStack1 = styled.div`
  overflow: hidden;

  flex-grow: 1;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
`;

const DataStack2 = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  text-align: right;
`;

const DataStack3 = styled.div`
  min-width: 116px;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const DividingLine = styled.div`
  flex-shrink: 0;
  width: 1px;
  height: 43px;
  margin: 0 16px;
  background-color: lightgrey;

  @media (max-width: 879px) {
    height: 64px;
  }
`;

const Major = styled.p`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  min-width: 0;
`;

const Price = styled.span`
  font-weight: 600;
  letter-spacing: 0.4px;
`;

const SubData = styled.p`
  font-size: 13px;
  color: #84869a;
  margin-top: 8px;
`;

const Percent = styled.span`
  font-weight: 500;
  color: ${props => (Math.sign(props.percent) === 1 ? "#EB5757" : "#27AE60")};
`;

// Mobile
const MobileStack1 = styled.div`
  width: 104px;
  flex-shrink: 0;
  text-align: right;
  display: flex;
  flex-direction: column;
`;

const MobileStack2 = styled.div`
  flex-grow: 1;
  // text-align: left;
  display: flex;
  flex-direction: column;

  overflow: hidden;
`;

let listItems = [];
let numResults = 0;

class Results extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false
    };
  }

  componentWillMount() {
    // create new array for results w location, and price comparison
    let formattedResults = this.props.searchResults;
    // round the avg lenght of stay
    for (var i = 0; i < formattedResults.length; i++) {
      formattedResults[i].ALOS = formattedResults[i].ALOS.toFixed(0);
    }
    numResults = formattedResults.length;
    this.grabStateAverage(formattedResults);
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  afterOpenModal = () => {
    // console.log("not sure what we do here");
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false, modalId: "" });
  };

  grabStateAverage = results => {
    for (var i = 0; i < results.length; i++) {
      for (var a = 0; a < averages.length; a++) {
        if (results[i].DRGCode === averages[a].DRGCode) {
          let num = Number(results[i].AvgChgPerStay.replace(/[^0-9.-]+/g, ""));
          let denom = Number(averages[a].AvgCost.replace(/[^0-9.-]+/g, ""));
          let calc = ((num - denom) / denom) * 100;
          let percent = (Math.round(calc * 10) / 10).toFixed(0);
          results[i].Percent = percent;
          if (percent > 0) {
            results[i].CompareString = `above avg`;
          } else {
            results[i].CompareString = `below avg`;
          }
        }
      }
    }
    this.grabHospitalData(results);
  };

  grabHospitalData = results => {
    for (var i = 0; i < results.length; i++) {
      for (var h = 0; h < hospitals.length; h++) {
        if (results[i].FacilityNumber === hospitals[h].OSHPD_ID) {
          results[i].Location = hospitals[h].DBA_CITY.toLowerCase() + ", CA";
        }
      }
    }
    if (this.props.viewportWidth >= 880) {
      this.mapListItemsDesktop(results);
    } else if (this.props.viewportWidth < 880) {
      this.mapListItemsMobile(results);
    }
  };

  mapListItemsDesktop = results => {
    listItems = results.map(result => (
      <ListItem
        key={
          this.props.searchType === "procedures"
            ? result.FacilityNumber
            : result.DRGCode
        }
        searchType={this.props.searchType}
        variants={items}
      >
        <DataStack1>
          <Major
            className={
              this.props.searchType === "hospitals" ? null : "capitalize"
            }
          >
            {this.props.searchType === "procedures"
              ? result.FacilityName
              : result.DRGDescription}
          </Major>

          <SubData className="capitalize">
            {this.props.searchType === "procedures"
              ? result.Location
              : `DRG code: ${result.DRGCode}`}
          </SubData>
        </DataStack1>
        <DataStack2>
          <Price> {result.AvgChgPerStay}</Price>
          <SubData>
            <Percent percent={result.Percent}>
              {Math.abs(result.Percent)}%
            </Percent>{" "}
            {result.CompareString}
          </SubData>
        </DataStack2>
        <DividingLine />
        <DataStack3>
          <span> {result.Discharges} discharges</span>
          <SubData>{result.ALOS} days avg</SubData>
        </DataStack3>
      </ListItem>
    ));
  };

  mapListItemsMobile = results => {
    listItems = results.map(result => (
      <ListItem
        key={
          this.props.searchType === "procedures"
            ? result.FacilityNumber
            : result.DRGCode
        }
        searchType={this.props.searchType}
        variants={items}
      >
        <MobileStack1>
          <Price> {result.AvgChgPerStay}</Price>
          <SubData>
            <Percent percent={result.Percent}>
              {Math.abs(result.Percent)}%
            </Percent>{" "}
            {result.CompareString}
          </SubData>
          <SubData>{result.ALOS} days avg</SubData>
        </MobileStack1>

        <DividingLine />

        <MobileStack2>
          <Major
            className={
              this.props.searchType === "hospitals" ? null : "capitalize"
            }
          >
            {this.props.searchType === "procedures"
              ? result.FacilityName
              : result.DRGDescription}
          </Major>
          <SubData className="capitalize">
            {this.props.searchType === "procedures"
              ? result.Location
              : `DRG code: ${result.DRGCode}`}
          </SubData>
          <SubData>{result.Discharges} discharges</SubData>
        </MobileStack2>
      </ListItem>
    ));
  };

  render() {
    return (
      <div>
        <StyledModal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
        >
          <ModalContent
            title={"W(hat) TF does all this mean?"}
            content={[
              <span>
                All of the information on this page comes from a 2017{" "}
                <span className="link-wrapper">
                  <a
                    className="link"
                    href="https://data.chhs.ca.gov/dataset/top-25-ms-drgs-individual-hospital-pivot-profile/resource/850f9fd8-026d-459b-9d48-b89fec8622a8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    dataset
                  </a>
                </span>{" "}
                from the California Health and Human Services (CHHS) Open Data
                Portal. This dataset reported the most common diagnoses (DRGs,
                see below) across hospitals in California. Note this is
                historical (2017) data displaying average costs, as opposed to
                forward-looking "prices", for example.
              </span>,
              <span>Here are what some of the key terms mean:</span>,
              <span>
                <b>Procedure:</b> the official name of the diagnosis (although
                many of these are more "procedures" e.g. appendectomy), as
                defined by the diagnosis-related group (DRG).
              </span>,
              <span>
                <b>DRG Code:</b> the unique identifier for the procedure, as
                defined by the diagnosis-related group (DRG). A DRG is a{" "}
                <span className="link-wrapper">
                  <a
                    className="link"
                    href="https://www.verywellhealth.com/drg-101-what-is-a-drg-how-does-it-work-3916755"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    fairly broad diagnosis
                  </a>
                </span>{" "}
                that is used to categorize hospitalization costs (primarily for
                Medicare and some health insurance companies). There's both good
                and bad here: basically, healthcare has some standards (thank
                goodness), but the descriptions aren't at all consumer-friendly
                (boo).
              </span>,
              <span>
                <b>Length of stay:</b> how long the patient stayed in the
                hospital for that DRG Code, on average. Note that there is quite
                a bit of variation here (e.g. between hospitals for the same
                diagnosis), which presumably has significant impact on the
                average cost.
              </span>,
              <span>
                <b>Cost:</b> the average cost for that DRG Code. Note that this
                is not technically the advertised "price" (I don't even think
                there is such a thing), but just how much the patient got
                charged (before insurance/Medicare, thank goodness) when they
                left the hospital (when they got discharged).{" "}
              </span>,
              <span>
                <b>% difference:</b> the calculated difference of that
                hospital's avg cost as compared to the avg cost across the
                entire state of California. I added this just so you can get a
                sense for if an avg cost is "good or bad". I mean I know they
                are all exorbitant → bad, but hey it's all relative, right guys?
                ....guys?
              </span>,
              <span>
                <b>Discharges:</b> the number of patients that had that
                diagnosis (DRG) in this data set (2017). Some of these numbers
                seem a bit low to me (this data is from a 365 day period), but
                what do I know I'm not a doctor. Proceed with caution for any
                hospital/procedure combinations that have low # of discharges -
                there was no outlier removal as far as I know, so this data
                could be easily skewed by just 1 discharge.
              </span>,
              <span>
                <b>Hospital:</b> the name and location of the hospital. Only
                limited to California hospitals for now, apologies. It's a
                populous state that has some solid data, so made sense to start
                there.
              </span>
            ]}
            closeModal={this.closeModal}
          />
        </StyledModal>
        <ResultsHeader>
          <p>{numResults} results</p>
          <WTFButton onClick={this.openModal}>WTF?</WTFButton>
        </ResultsHeader>
        <ResultsWrapper
          initial="hidden"
          animate="visible"
          variants={this.props.viewportWidth < 880 ? mobileList : desktopList}
        >
          {listItems}
        </ResultsWrapper>
      </div>
    );
  }
}

Results.propTypes = {
  searchResults: PropTypes.array,
  searchType: PropTypes.string.isRequired,
  viewportWidth: PropTypes.number.isRequired
};

export default Results;
