import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import Results from "./Results";
import SelectedParent from "./SelectedParent";
import SearchPage from "./SearchPage";

import wordmark from "../Assets/lora-wordmark-dark.svg";

const TaskWrapper = styled.div`
  // max-width: 1200px;
  // margin: 0 auto;
`;

const Wordmark = styled(motion.img)`
  width: 184px;
  position: fixed;
  bottom: 20px;

  @media (max-width: 879px) {
    display: none;
  }
`;

const ResultsWrapper = styled.div`
  width: 100%;
  max-width: 1096px;
  padding: 0 8px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: 879px) {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    padding: 0;
    flex-direction: column;
  }
`;

const LeftWrapper = styled(motion.div)`
  width: 348px;
  flex-shrink: 0;
  margin-top: 24px;
  display: flex;

  @media (max-width: 879px) {
    width: 100%;
    display: block;
  }
`;

const RightWrapper = styled(motion.div)`
  flex-grow: 1;
  margin-left: 32px;
  margin-top: 40px;
  max-width: ${props => props.width}px;
  padding-bottom: 24px;

  @media (max-width: 879px) {
    width: 100%;
    max-width: 100vw;
    margin-left: 0;
    margin-top: 48px;
    display: block;
  }
`;

class Task extends React.Component {
  constructor() {
    super();
    this.state = {
      searchType: "procedures",
      selected: false,
      results: false,
      resultArray: [],
      selectedThing: {},
      viewportWidth: null,
      maxWidth: null,
      firstSearch: true
    };
  }

  componentWillMount() {
    let viewportWidth = window.innerWidth;
    let width1 = viewportWidth - 396;
    let width2 = 1096 - 396;
    let calcMaxWidth = Math.min(width1, width2);
    this.setState({ viewportWidth: viewportWidth, maxWidth: calcMaxWidth });
  }

  handleLoading = () => {
    // let date = new Date();
    // console.log("we should load " + date.getMilliseconds());
  };

  handleResults = (selection, results) => {
    // let date = new Date();
    // console.log("handling results " + date.getMilliseconds());
    // console.log(selection);
    this.setState({
      loading: false,
      results: true,
      resultArray: results,
      selectedThing: selection
    });
  };

  handleBack = () => {
    this.setState({
      results: false,
      loading: false,
      resultArray: [],
      selectedThing: {},
      firstSearch: false
    });
  };

  handleRadioSelect = e => {
    this.setState({
      searchType: e.target.value
    });
  };

  render() {
    return (
      <TaskWrapper>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, delay: 0.4 }}
        >
          {!this.state.results && (
            <SearchPage
              searchType={this.state.searchType}
              handleLoading={this.handleLoading}
              handleResults={this.handleResults}
              handleRadioSelect={this.handleRadioSelect}
              firstSearch={this.state.firstSearch}
              viewportWidth={this.state.viewportWidth}
            />
          )}

          {this.state.results && (
            <ResultsWrapper>
              <LeftWrapper
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <SelectedParent
                  selectedThing={this.state.selectedThing}
                  searchResults={this.state.resultArray}
                  handleBack={this.handleBack}
                  searchType={this.state.searchType}
                />
                <Wordmark
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.5 }}
                  // tran={{opacity: 0}}
                  src={wordmark}
                />
              </LeftWrapper>
              <RightWrapper
                width={this.state.maxWidth}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                <Results
                  searchResults={this.state.resultArray}
                  searchType={this.state.searchType}
                  viewportWidth={this.state.viewportWidth}
                />
              </RightWrapper>
            </ResultsWrapper>
          )}
        </motion.div>
      </TaskWrapper>
    );
  }
}

export default Task;
