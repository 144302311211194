import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import averages from "../Data/Average.json";

const HospitalName = styled.div`
  margin-bottom: 40px;
  color: #131739;
`;

const StatFlex = styled.div`
  display: block;
  & :nth-child(2) {
    margin-top: 24px;
  }

  @media (max-width: 879px) {
    display: flex;
    & :nth-child(2) {
      margin-top: 0;
    }
  }
`;

const StatWrapper = styled.div`
  width: 100%;
`;

const StatLabel = styled.h5`
  font-size: 12px;
  letter-spacing: 0.6px;
  font-weight: 700;
  text-transform: uppercase;
  color: #84869a;

  @media (max-width: 879px) {
    font-size: 11px;
  }
`;

const Stat = styled.p`
  font-size: 24px;
  margin-top: 4px !important;
  color: #131739;

  @media (max-width: 879px) {
    font-size: 20px;
  }
`;

const Percent = styled.span`
  font-weight: 500;
  color: ${props => (Math.sign(props.percent) === 1 ? "#EB5757" : "#27AE60")};
`;

class SelectedHospital extends React.Component {
  constructor() {
    super();
    this.state = {
      numProcedures: null,
      avgCost: null,
      compareString: null
    };
  }

  componentWillMount() {
    // calculate procedures
    this.calculateProcedures(this.props.searchResults);
  }

  calculateProcedures = results => {
    let length = results.length;
    // this.setState({
    //   numProcedures: length
    // });

    this.calculateAverageCost(length, results);
  };

  calculateAverageCost = (length, results) => {
    let totalDifference = 0;
    for (let i = 0; i < length; i++) {
      for (var a = 0; a < averages.length; a++) {
        if (results[i].DRGCode === averages[a].DRGCode) {
          let num = Number(results[i].AvgChgPerStay.replace(/[^0-9.-]+/g, ""));
          let denom = Number(averages[a].AvgCost.replace(/[^0-9.-]+/g, ""));
          let calc = ((num - denom) / denom) * 100;
          let percent = Number((Math.round(calc * 10) / 10).toFixed(0));
          totalDifference += percent;
        }
      }
    }

    let avgMarkup = Math.round(totalDifference / length);
    let avgString;
    if (avgMarkup >= 0) {
      avgString = ` above CA avg`;
    } else {
      avgString = ` below CA avg`;
    }

    this.setState({
      numProcedures: length,
      avgMarkup: avgMarkup,
      compareString: avgString
    });
  };

  render() {
    return (
      <div>
        <HospitalName>
          <h4 className="capitalize">
            {this.props.selectedHospital.PrimaryInfo}
          </h4>
        </HospitalName>

        <StatFlex>
          <StatWrapper>
            <StatLabel>Avg costs</StatLabel>
            {/* <Stat>{this.state.avgCost}</Stat> */}
            <Stat>
              <Percent percent={this.state.avgMarkup}>
                {Math.abs(this.state.avgMarkup)}%
              </Percent>{" "}
              {this.state.compareString}
            </Stat>
          </StatWrapper>
          {/* <StatWrapper>
            <StatLabel># of procedures</StatLabel>
            <Stat>{this.state.numProcedures}</Stat>
          </StatWrapper> */}
        </StatFlex>
      </div>
    );
  }
}

SelectedHospital.propTypes = {
  selectedHospital: PropTypes.object.isRequired,
  searchResults: PropTypes.array.isRequired
};

export default SelectedHospital;
